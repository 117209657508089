<template>
  <div id="gift-card">
    <script2>
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-WHRL4W9');
    </script2>

    <h2 aria-label="Aira Gift Credit">
      Aira Gift Credit
    </h2>

    <b-form
      class="form-container"
      @submit.prevent
    >
      <FormProgressBar :progress="progress()" />
      <!-- ERRORS -->
      <FormErrors />
      <div class="form">
        <!-- YOUR INFORMATION -->
        <FormSection title="Your Information">
          <FormGroup
            for="gifterName"
            label="Your Full Name*"
            class="w-100"
          >
            <FormInput
              v-validate="{ required: true }"
              :state="validateState('Gifter Name')"
              name="Gifter Name"
              bind-id="gifterName"
              invalid-feedback="Please enter your name."
              invalid-feedback-id="gifter-name-feedback"
              get="gifterName"
              set="UPDATE_GIFTER_NAME"
              required
            />
          </FormGroup>
        </FormSection>

        <!-- ===== PHONE VERIFICATION ===== -->
        <FormSection
          title="Phone Verification"
          description="Please provide your cell phone number. We need this so we can verify your purchase and send a confirmation message to your phone."
        >
          <div class="d-inline-flex justify-content-between form-inline-input">
            <FormGroup
              for="countryCode"
              label="Country*"
              valid-feedback="Phone number is verified!"
              class="w-25-35"
            >
              <FormSelect
                :options="countryCodeOptions"
                bind-id="countryCode"
                default="+1"
                get="countryCode"
                set="UPDATE_COUNTRY_CODE"
                required
              />
            </FormGroup>
            <FormGroup
              :state="state"
              for="phoneNumber"
              label="Phone Number*"
              invalid-feedback="Please re-verify the phone number."
              valid-feedback="Phone number is verified!"
              class="w-75-65"
            >
              <FormInput
                ref="phoneNumber"
                :state="state"
                :disabled="phoneVerifyButtonDisabled || phoneVerified || verifying"
                bind-id="phoneNumber"
                type="tel"
                placeholder="Mobile Number"
                get="phoneNumber"
                set="UPDATE_PHONE_NUMBER"
                required
                @keyup.enter.native="sendCode"
              />
            </FormGroup>
          </div>
          <b-button
            id="phone-verify-button"
            :disabled="disabled || phoneVerified"
            variant="primary"
            class="form-inline-button"
            @click="sendCode"
          >
            Verify
          </b-button>
        </FormSection>
        <FormSection
          v-if="verifying"
          :description="`Enter the 4-digit code texted to you at [${phone}]`"
        >
          <FormGroup
            id="verification-code"
            :state="state"
            :class="[{'invalid': state}]"
            :invalid-feedback="invalidFeedback"
            for="auth"
            role="alert"
          >
            <b-input
              id="verification-code-field"
              v-model="auth"
              type="tel"
              maxlength="4"
            />
          </FormGroup>
        </FormSection>
        <b-button
          v-if="verifying"
          :disabled="disabled"
          class="link-button"
          size="sm"
          variant="link"
          @click="sendCode"
        >
          {{ resendCodeText }}
        </b-button>
        <b-button
          v-if="verifying"
          class="link-button"
          size="sm"
          variant="link"
          @click="back"
        >
          Back
        </b-button>
        <!-- GIFT CARD OPTIONS -->
        <FormSection
          v-if="phoneVerified && giftCardOptions"
          title="Gift Card Options"
        >
          <FormGroup for="giftCardOptions">
            <FormSelect
              v-model="giftCardSelected"
              v-validate="{ required: true }"
              :state="validateState('Gift Card Option')"
              :option-groups="giftCardOptions"
              name="Gift Card Option"
              bind-id="giftCardOptions"
              default=""
              invalid-feedback="Gift Card Option is a required field."
              invalid-feedback-id="gift-card-invalid-feedback"
              get="giftCardSelected"
              set="SET_GIFT_CARD_SELECTED"
              required
            />
          </FormGroup>
        </FormSection>
        <!-- GIFTEE -->
        <FormSection
          v-if="phoneVerified"
          title="Recipient Information"
        >
          <!-- FIRST & LAST NAME -->
          <div class="d-sm-flex justify-content-sm-between">
            <FormGroup
              for="recipientFirstName"
              label="Recipient First Name*"
              class="w-50-100"
            >
              <FormInput
                v-validate="{ required: true }"
                :state="validateState('Recipient First Name')"
                name="Recipient First Name"
                bind-id="recipientFirstName"
                invalid-feedback="Recipient First name is a required field."
                invalid-feedback-id="giftee-first-name-invalid-feedback"
                get="gifteeFirstName"
                set="UPDATE_GIFTEE_FIRST_NAME"
                required
              />
            </FormGroup>
            <FormGroup
              for="recipientLastName"
              label="Recipient Last Name*"
              class="w-50-100"
            >
              <FormInput
                v-validate="{ required: true }"
                :state="validateState('Recipient Last Name')"
                name="Recipient Last Name"
                bind-id="recipientLastName"
                invalid-feedback="Recipient Last name is a required field."
                invalid-feedback-id="giftee-last-name-invalid-feedback"
                get="gifteeLastName"
                set="UPDATE_GIFTEE_LAST_NAME"
                required
              />
            </FormGroup>
          </div>
          <!-- COUNTRY CODE & PHONE NUMBER -->
          <div class="d-flex justify-content-between">
            <FormGroup
              for="recipientCountryCode"
              label="Recipient Country*"
              class="w-25-35"
            >
              <FormSelect
                :options="countryCodeOptions"
                bind-id="recipientCountryCode"
                default="+1"
                get="gifteeCountryCode"
                set="UPDATE_GIFTEE_COUNTRY_CODE"
                required
              />
            </FormGroup>
            <FormGroup
              for="recipientPhoneNumber"
              label="Recipient Phone Number*"
              class="w-75-65"
            >
              <FormInput
                v-validate="{ required: true }"
                :state="validateState('Recipient Phone Number')"
                name="Recipient Phone Number"
                bind-id="recipientPhoneNumber"
                invalid-feedback="Recipient Phone Number is a required field."
                invalid-feedback-id="giftee-phone-number-invalid-feedback"
                get="gifteePhoneNumber"
                set="UPDATE_GIFTEE_PHONE_NUMBER"
                required
              />
            </FormGroup>
          </div>
        </FormSection>

        <!-- Payment Profile -->
        <FormSection
          v-if="ccLastFour && cardType"
          :description="`${cardType} ending in ${ccLastFour}`"
          title="Payment Method"
        >
          <FormGroup
            for="isManualPayment"
          >
            <FormInput
              bind-id="isManualPayment"
              type="checkbox"
              label="Use a different payment method"
              get="isManualPayment"
              set="TOGGLE_IS_MANUAL_PAYMENT"
              class="w-100"
            />
          </FormGroup>
        </FormSection>

        <!-- recurly script -->
        <div v-show="!ccLastFour && phoneVerified">
          <script2 src="https://js.recurly.com/v4/recurly.js" />
          <script2 src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
          <!-- eslint-disable -->
          <script2>
            $(document).ready(function () {
              recurly.configure({
                publicKey: '{{publicKey}}',
              });
            });

            recurly.on('field:submit', function (event) {
              event.preventDefault();
            });

            $('form').on('submit', function (event) {
              event.preventDefault();
              var form = this;

              recurly.token(form, function (err, token) {
                if (err) {
                  console.log(err);
                  alert(err.message + ' Please make sure your credit card number and expiration date are correct.');
                } else {
                  $('#token').val(token.id);
                  $('#billingButton').click();
                }
              });
            });

          </script2>
          <!-- eslint-enable -->
        </div>

        <form
          v-show="isManualPayment && phoneVerified"
          :action="`/api/account/${accountCode}`"
          method="put"
        >
          <!-- BILLING INFO -->
          <FormSection
            title="Billing Information"
          >
            <div class="d-flex justify-content-between">
              <FormGroup
                for="firstName"
                label="First Name*"
                class="w-50"
              >
                <FormInput
                  v-validate="{ required: true }"
                  :state="validateState('Billing First Name')"
                  name="Billing First Name"
                  bind-id="firstName"
                  data-recurly="first_name"
                  invalid-feedback="Billing First Name Required."
                  invalid-feedback-id="billing-first-name-invalid-feedback"
                  get="ccFirstName"
                  set="UPDATE_CC_FIRST_NAME"
                  required
                />
              </FormGroup>
              <FormGroup
                for="lastName"
                label="Last Name*"
                class="w-50"
              >
                <FormInput
                  v-validate="{ required: true }"
                  :state="validateState('Billing Last Name')"
                  name="Billing Last Name"
                  bind-id="lastName"
                  data-recurly="last_name"
                  invalid-feedback="Billing Last Name Required."
                  invalid-feedback-id="billing-last-name-invalid-feedback"
                  get="ccLastName"
                  set="UPDATE_CC_LAST_NAME"
                  required
                />
              </FormGroup>
            </div>
            <FormGroup
              for="address1"
              label="Address*"
              class="w-100"
            >
              <FormInput
                v-validate="{ required: true }"
                :state="validateState('Address')"
                name="Address"
                bind-id="address1"
                data-recurly="address1"
                invalid-feedback="Address Required."
                invalid-feedback-id="address1-invalid-feedback"
                get="address1"
                set="UPDATE_ADDRESS1"
                required
              />
            </FormGroup>
            <div class="d-flex justify-content-between">
              <FormGroup
                for="country"
                label="Country*"
                class="w-50"
              >
                <FormSelect
                  :options="countryOptions"
                  :state="null"
                  name="country"
                  bind-id="country"
                  data-recurly="country"
                  invalid-feedback="Country Required."
                  invalid-feedback-id="country-invalid-feedback"
                  get="country"
                  set="UPDATE_COUNTRY"
                  required
                />
              </FormGroup>
              <FormGroup
                for="state"
                label="State/Province/Region*"
                class="w-50"
              >
                <FormSelect
                  :options="stateOptions"
                  :state="null"
                  name="state"
                  bind-id="state"
                  data-recurly="state"
                  invalid-feedback="State Required."
                  invalid-feedback-id="state-invalid-feedback"
                  get="state"
                  set="UPDATE_STATE"
                  required
                />
              </FormGroup>
            </div>
            <div class="d-flex justify-content-between">
              <FormGroup
                for="city"
                label="City*"
                class="w-50"
              >
                <FormInput
                  v-validate="{ required: true }"
                  :state="validateState('City')"
                  name="City"
                  bind-id="city"
                  data-recurly="city"
                  invalid-feedback="City Required."
                  invalid-feedback-id="city-invalid-feedback"
                  get="city"
                  set="UPDATE_CITY"
                  required
                />
              </FormGroup>
              <FormGroup
                for="zip"
                label="Zip or Postal Code*"
                class="w-50"
              >
                <FormInput
                  v-validate="{ required: true }"
                  :state="validateState('Zip')"
                  name="Zip"
                  bind-id="zip"
                  data-recurly="postal_code"
                  invalid-feedback="Zip or Postal Code Required."
                  invalid-feedback-id="zip-invalid-feedback"
                  get="zip"
                  set="UPDATE_ZIP"
                  required
                />
              </FormGroup>
            </div>
          </FormSection>
          <FormSection
            title="Payment Information"
          >
            <FormGroup
              for="cc"
              label="Card Number*"
              class="w-100"
            >
              <div
                class="recurly-field"
                role="none"
                data-recurly="number"
              />
            </FormGroup>
            <div class="d-flex justify-content-between">
              <FormGroup
                for="expiryMonth"
                label="Expiration Month (MM)*"
                class="w-50"
              >
                <div
                  class="recurly-field"
                  role="none"
                  data-recurly="month"
                />
              </FormGroup>
              <FormGroup
                for="expiryYear"
                label="Expiration Year (YYYY)*"
                class="w-50"
              >
                <div
                  class="recurly-field"
                  role="none"
                  data-recurly="year"
                />
              </FormGroup>
            </div>
            <FormGroup
              for="cvv"
              label="CVV*"
              class="w-50"
            >
              <FormInput
                :state="null"
                name="CVV"
                bind-id="cvv"
                data-recurly="cvv"
                invalid-feedback="CVV Required."
                invalid-feedback-id="cvv-invalid-feedback"
                get="cvv"
                set="UPDATE_CVV"
                required
              />
            </FormGroup>
          </FormSection>
          <input
            id="token"
            ref="token"
            type="hidden"
            name="recurly-token"
            data-recurly="token"
          >
          <!-- ===== ORDER SUMMARY ===== -->
          <b-btn
            v-if="!orderReviewed && isManualPayment"
            :disabled="previewButtonDisabled"
            type="submit"
            class="signup-buttons w-75"
            size="lg"
            variant="secondary"
          >
            <span v-if="previewButtonDisabled">
              <b-spinner label="processing" />
            </span>
            <span v-else>Get Order Summary</span>
          </b-btn>
          <b-btn
            id="billingButton"
            hidden
            @click="getOrderSummaryPreview()"
          />
        </form>
      </div>
      <div>
        <b-button
          v-if="!orderReviewed && ccLastFour && !isManualPayment"
          :disabled="previewButtonDisabled"
          class="signup-buttons w-75"
          size="lg"
          variant="secondary"
          @click="getOrderSummaryPreview()"
        >
          <span v-if="previewButtonDisabled">
            <b-spinner label="processing" />
          </span>
          <span v-else>Get Order Summary</span>
        </b-button>
        <SignupOrderSummary v-if="orderSummary.length && orderReviewed" />
        <b-button
          v-if="orderReviewed"
          :disabled="submitButtonDisabled"
          class="signup-buttons w-75"
          size="lg"
          variant="primary"
          @click="postGiftCardOrder()"
        >
          <span v-if="submitButtonDisabled">
            <b-spinner label="submitting" />
          </span>
          <span v-else>Submit</span>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ValidationMixin from '../mixin/ValidationMixin';

import FormErrors from './Form/FormErrors.vue';
import FormProgressBar from './Form/FormProgressBar.vue';
import FormSection from './Form/FormSection.vue';
import FormGroup from './Form/FormGroup.vue';
import FormInput from './Form/FormInput.vue';
import FormSelect from './Form/FormSelect.vue';
import SignupOrderSummary from './Signup/SignupComponents/SignupOrderSummary.vue';

export default {
  name: 'GiftCard',
  components: {
    FormErrors,
    FormProgressBar,
    FormSection,
    FormGroup,
    FormInput,
    FormSelect,
    SignupOrderSummary,
  },
  mixins: [
    ValidationMixin,
  ],
  data() {
    return {
      prod: process.env.VUE_APP_ENV_NAME === 'prod',
      previewButtonDisabled: false,
      orderReviewed: false,
      submitButtonDisabled: false,
      phoneVerifyButtonDisabled: false,
      phoneVerified: false,
      phoneVerifyStatus: null,
      token: '',
      publicKey: process.env.VUE_APP_RECURLY_PUBLIC_KEY,
      disabled: false,
      auth: '',
      verifying: false,
      timer: 0,
      state: null,
      invalidFeedback: '',
    };
  },
  computed: {
    ...mapGetters([
      'errors',
      'accountCode',
      'giftCardOptions',
      'countryOptions',
      'countryCodeOptions',
      'stateOptions',
      'giftCardSelected',
      'phone',
      'phoneNumber',
      'gifteePhone',
      'ccLastFour',
      'cardType',
      'orderSummary',
      'isManualPayment',
    ]),
    resendCodeText() {
      if (this.timer > 0) {
        return `Resend Code in ${this.timer}`;
      } else {
        return 'Resend Code';
      }
    },
  },
  watch: {
    auth(val) {
      if (val.length === 4) {
        this.verify();
      }
    },
    giftCardSelected() {
      this.orderReviewed = false;
    },
  },
  mounted() {
    this.$store.dispatch('getGiftCardOptions');
  },
  methods: {
    progress() { return { full: true }; },
    back() {
      this.verifying = false;
      this.disabled = false;
    },
    async sendCode() {
      if (this.errors.length) this.$store.commit('CLEAR_ERRORS');
      this.disabled = true;
      if (this.phoneNumber.length < 7) {
        this.state = false;
        this.invalidFeedback = 'Error: invalid number';
        this.disabled = false;
      } else {
        try {
          await this.$store.dispatch('signupVerify');
          this.verifying = true;
          this.state = null;
          this.invalidFeedback = '';
          this.minuteCountdown();
        } catch (error) {
          console.error(error);
          this.state = false;
          this.invalidFeedback = error.errorMessage;
          this.disabled = false;
        }
      }
    },
    async verify() {
      if (this.errors.length) this.$store.commit('CLEAR_ERRORS');
      try {
        const response = await this.$store.dispatch('signupVerifyConfirm', this.auth);
        if (response) {
          if (response.newUser) {
            // create new guest account & login
            const payload = {
              login: this.phone,
              verificationCode: response.verificationCode,
            };
            await this.$store.dispatch('doGuestSignupBasic', payload);
            const login = await this.$store.dispatch('login', {
              login: this.phone,
              password: response.verificationCode,
              authProvider: 'PHONE_VERIFICATION',
              loginfrom: 'SSP',
            });
            if (login) {
              this.$store.commit('TOGGLE_PHONE_VERIFIED', true);
              this.$store.commit('TOGGLE_IS_MANUAL_PAYMENT');
              this.phoneVerified = true;
              this.verifying = false;
              this.state = true;
            }
          } else { // existing user
            // login and set guest
            const login = await this.$store.dispatch('login', {
              login: this.phone,
              password: response.verificationCode,
              authProvider: 'PHONE_VERIFICATION',
              loginfrom: 'SSP',
            });
            if (login) {
              this.$store.commit('TOGGLE_PHONE_VERIFIED', true);
              await this.$store.dispatch('getUserBilling');
              if (!this.ccLastFour) this.$store.commit('TOGGLE_IS_MANUAL_PAYMENT');
              this.phoneVerified = true;
              this.verifying = false;
              this.state = true;
            } else {
              this.state = false;
              this.invalidFeedback = 'error';
              this.auth = '';
            }
          }
          // update country code options to disable
          // const options = this.countryCodeOptions;
          // options.forEach((option) => {
          //   // eslint-disable-next-line
          //   if (option.value !== this.countryCode) option.disabled = true;
          // });
          // this.$store.commit('SET_COUNTRY_CODE_OPTIONS', options);
        } else {
          this.state = false;
          this.invalidFeedback = 'error';
          this.auth = '';
        }
      } catch (error) {
        console.error(error);
        this.state = false;
        // this.invalidFeedback = `Error: ${error.errorMessage}`;
        this.invalidFeedback = 'Invalid. Try again.';
        this.auth = '';
      }
    },
    minuteCountdown() {
      this.disabled = true;
      this.timer = 60;
      const minuteTimer = setInterval(() => {
        this.timer -= 1;
        if (this.timer <= 0) {
          this.disabled = false;
          clearInterval(minuteTimer);
        }
      }, 1000);
    },
    async getOrderSummaryPreview() {
      if (this.errors.length) this.$store.commit('CLEAR_ERRORS');
      if (this.$refs.token.value) {
        this.$store.commit('UPDATE_RECURLY_CODE', this.$refs.token.value);
      }
      this.previewButtonDisabled = true;

      // do
      try {
        // validation
        const validation = await this.$validator.validateAll();

        if (!validation) {
          const fieldErrors = await this.veeErrors.items.map((item) => item.msg);
          this.$store.commit('PUSH_ERRORS', fieldErrors);
          this.previewButtonDisabled = false;
          return;
        }

        const response = await this.$store.dispatch('getGiftCardOrderSummary');
        if (response) {
          this.orderReviewed = true;
        }
        this.previewButtonDisabled = false;
      } catch (error) {
        console.error(error);
        this.previewButtonDisabled = false;
      }
    },
    async postGiftCardOrder() {
      this.submitButtonDisabled = true;
      if (this.errors.length) this.$store.commit('CLEAR_ERRORS');

      // do
      try {
        // validation
        const validation = await this.$validator.validateAll();

        if (!validation) {
          const fieldErrors = await this.veeErrors.items.map((item) => item.msg);
          this.$store.commit('PUSH_ERRORS', fieldErrors);
          this.submitButtonDisabled = false;
          return;
        } else {
          const response = await this.$store.dispatch('postGiftCardOrder');
          if (response) {
            window.location.href = 'https://aira.io/gift-thank-you';
          }
          this.submitButtonDisabled = false;
        }
      } catch (error) {
        console.error(error);
        this.submitButtonDisabled = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/variables/_colors.scss';
@import '../styles/variables/_sizes.scss';
@import '../styles/theme/_default.scss';

$char-w: 1ch;
$gap: .4*$char-w;
$n-char: 4;
$in-w: $n-char*($char-w + $gap);

#gift-card {
  margin-top: 40px;
  .form {
    padding: 40px 20px 0px 20px;
  }
  button {
    display: block;
    margin: 0 auto;
  }
  #phone-verify-button {
    margin-top: 21px;
  }
  .link-button {
    height: 25px;
  }
  .invalid-feedback {
    position: absolute;
  }
  #verification-code {
    width: 170px;
    height: 55px;
    margin: 2em auto;
    border: 1px solid;
    padding: 0px 25px 5px 30px;
    border-radius: 8px;
    &:focus-within {
      border: 1px solid #21BECE;
    }
    .invalid-feedback {
      display: inline-flex !important;
      position: relative;
      margin-left: -50px;
      margin-top: 10px;
      width: 250px;
    }
    #verification-code-field {
      border-radius: 0 !important;
      border: 0 !important;
      padding: 0 !important;
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 36px !important;
      line-height: 43px;
      display: block;
      width: $in-w;
      background: repeating-linear-gradient(90deg,
        dimgrey 0, dimgrey $char-w,
        transparent 0, transparent $char-w + $gap)
        0 100%/ #{$in-w - $gap} 3px no-repeat;
      letter-spacing: $gap;
      &:focus {
        outline: none;
        box-shadow: none !important;
      }
    }
  }
}
</style>
